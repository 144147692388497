<igx-drop-down class="supy-dropdown" [attr.data-name]="name" [width]="width">
  <div class="items-list-actions--initial-loading"></div>

  <div [ngClass]="getCustomClasses()">
    <igx-drop-down-item
      *ngFor="let item of children"
      class="supy-dropdown-item"
      [routerLink]="item.routerLink"
      [ngClass]="item.class"
      [disabled]="item.disabled"
      (click)="item.click.emit($event)"
    >
      <ng-template [ngTemplateOutlet]="item.template"></ng-template>
    </igx-drop-down-item>
  </div>
</igx-drop-down>
