export interface Role {
  readonly id: string;
  readonly name: string;
  readonly identifier: string;
  readonly description?: string;
  readonly permissions: string[];
  readonly scope: RoleScope;
  readonly default?: boolean;
  readonly disabled?: boolean;
}

export interface RoleScope {
  readonly type: RoleScopeType;
  readonly reference?: string;
}

export enum RoleScopeType {
  Admin = 'admin',
  Retailer = 'retailer',
  Branch = 'branch',
  Superadmin = 'superadmin',
}

export const RESTRICTED_SCOPES: Set<RoleScopeType> = new Set([RoleScopeType.Admin, RoleScopeType.Superadmin]);
