<igx-select
  class="igx-input-group--{{ variant }} {{ density }} supy-input--compact"
  [class.selected]="!!value"
  [overlaySettings]="overlaySettings"
  [placeholder]="placeholder"
  theme="material"
  [attr.data-name]="name"
  [type]="selectType"
  [value]="value"
  [disabled]="disabled"
  (selectionChanging)="onSelect($event)"
  (opening)="onFocus()"
  (opened)="searchEnabled && focusOnSearch()"
  (closed)="onClose()"
  [class.supy-select-line]="selectType === 'line'"
>
  <igx-prefix>
    <ng-content select="supy-icon"></ng-content>
  </igx-prefix>

  <igx-select-item *ngFor="let item of children; trackBy: trackByValue" [value]="item.value" [disabled]="item.disabled">
    <ng-template [ngTemplateOutlet]="item.template"></ng-template>
  </igx-select-item>

  <igx-suffix *ngIf="value !== null && value !== undefined && clearable && !disabled" class="clear-button">
    <supy-icon (click)="clearSelection($event)" size="small" name="close-circle" color="error"></supy-icon>
  </igx-suffix>

  <igx-suffix *ngIf="!hideArrow && !loading && !disabled">
    <supy-icon class="toggle-icon" name="arrow-circle-down" [size]="arrowSize"></supy-icon>
  </igx-suffix>

  <igx-suffix *ngIf="showSearchIcon && !loading && !disabled">
    <supy-icon size="medium" name="search" shape="rounded"></supy-icon>
  </igx-suffix>

  <igx-suffix *ngIf="loading">
    <supy-loader size="small" class="loading" color="primary"></supy-loader>
  </igx-suffix>

  <ng-template *ngIf="selectHeaderTemplate?.template" igxSelectHeader>
    <ng-container [ngTemplateOutlet]="selectHeaderTemplate.template"></ng-container>
  </ng-template>

  <ng-template *ngIf="selectFooterTemplate?.template" igxSelectFooter>
    <ng-container [ngTemplateOutlet]="selectFooterTemplate.template"></ng-container>
  </ng-template>

  <ng-template *ngIf="searchEnabled" igxSelectHeader>
    <supy-input
      [attr.id]="searchId"
      name="select-search"
      placeholder="Search..."
      (valueChange)="onSearch($event)"
      suffix="search"
      autocomplete="off"
    ></supy-input>
  </ng-template>
</igx-select>
<supy-input-hint-container>
  <ng-content select="supy-input-error"></ng-content>
  <ng-content select="supy-input-hint"></ng-content>
</supy-input-hint-container>
