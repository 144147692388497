import { getLocaleDirection } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  CellType,
  DefaultDataCloneStrategy,
  FilteringStrategy,
  FilterMode,
  GridPagingMode,
  GridSelectionMode,
  IColumnMovingEndEventArgs,
  IDataCloneStrategy,
  IDropDroppedEventArgs,
  IFilteringEventArgs,
  IFilteringExpressionsTree,
  IFilteringStrategy,
  IGridCellEventArgs,
  IGridEditDoneEventArgs,
  IGridEditEventArgs,
  IGridSortingStrategy,
  IgxGridComponent,
  IgxStringFilteringOperand,
  IRowDataEventArgs,
  IRowDragEndEventArgs,
  IRowSelectionEventArgs,
  ISortingEventArgs,
  ISortingExpression,
  RowType,
} from '@infragistics/igniteui-angular';
import { IgxGridCRUDService } from '@infragistics/igniteui-angular/lib/grids/common/crud.service';
import { IgxRowDirective } from '@infragistics/igniteui-angular/lib/grids/row.directive';

import { Destroyable } from '@supy/common';

import { GridColumnComponent } from '../grid-column';
import {
  GridContextMenuTemplateDirective,
  GridDetailTemplateDirective,
  GridRowSelectorTemplateDirective,
} from './grid.directives';

type PaginationIcon = 'left-caret' | 'right-caret';

@Component({
  selector: 'supy-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent<T = unknown> extends Destroyable {
  @ViewChild(IgxGridComponent, { static: true }) readonly igxGrid: IgxGridComponent;

  @ContentChild(GridDetailTemplateDirective)
  protected readonly detailTemplate: GridDetailTemplateDirective;

  @ContentChild(GridRowSelectorTemplateDirective)
  protected readonly rowSelectorTemplate: GridRowSelectorTemplateDirective;

  @ContentChild(GridContextMenuTemplateDirective)
  protected readonly contextMenuTemplate: GridContextMenuTemplateDirective;

  #expandAll: boolean;

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get hasMovingColumns(): boolean {
    return this.columns?.some(({ movable }) => movable);
  }

  @Input()
  readonly data: T[];

  @Input()
  set height(height: string | null) {
    this.#height = height;
  }

  get height(): string | null {
    return this.#height;
  }

  @Input() protected readonly page: number;
  @Input() protected readonly perPage: number;
  @Input() protected readonly nextPageDisabled: boolean;
  @Input() protected readonly prevPageDisabled: boolean;
  @Input() protected readonly paginationMode: GridPagingMode;

  @Input()
  set width(width: string | null) {
    this.#width = width;
  }

  get width(): string | null {
    return this.#width;
  }

  @Input()
  readonly cellSelection: GridSelectionMode = GridSelectionMode.multiple;

  @Input()
  readonly rowSelection: GridSelectionMode = GridSelectionMode.none;

  @Input()
  readonly hideRowSelectors: boolean;

  @Input()
  readonly autoGenerate: boolean = false;

  @Input()
  readonly allowFiltering: boolean = false;

  @Input()
  readonly filterStrategy: IFilteringStrategy = new FilteringStrategy();

  @Input()
  readonly sortStrategy: IGridSortingStrategy;

  @Input()
  readonly filterMode: FilterMode = FilterMode.quickFilter;

  @Input()
  readonly selectRowOnClick: boolean = false;

  @Input()
  readonly primaryKey: string;

  @Input()
  readonly clipboardOptions: IgxGridComponent['clipboardOptions'] = {
    enabled: false,
    copyHeaders: false,
    separator: '\t',
    copyFormatters: false,
  };

  @Input()
  readonly minRowHeight: number;

  @HostBinding('class.supy-grid--flexible')
  @Input()
  readonly rowHeightFlexible: boolean = true;

  @Input()
  readonly rowEditable: boolean = false;

  @Input()
  readonly rowClasses: IgxGridComponent['rowClasses'];

  @Input()
  readonly emptyGridTemplate: TemplateRef<void>;

  @Input()
  readonly selectedRows: unknown[];

  @Input() readonly editableActions: boolean;

  @Input() readonly showAddRowEmptyButton: boolean = true;

  @Input() readonly rowDraggable: boolean;

  @Input() readonly lazyDeletion: boolean;
  @Input() readonly lazyAdd: boolean;

  @Input() readonly showAddAction: boolean = false;
  @Input() readonly showEditAction: boolean = true;
  @Input() readonly showDeleteAction: boolean = true;

  @Input()
  get dataCloneStrategy(): IDataCloneStrategy {
    return this.#dataCloneStrategy;
  }

  set dataCloneStrategy(strategy: IDataCloneStrategy) {
    if (strategy) {
      this.#dataCloneStrategy = strategy;
    }
  }

  @Input() set expandAll(value: boolean) {
    if (value) {
      this.#expandAll = true;
      this.igxGrid.expandAll();
    }
  }

  @Input() readonly addedInFooter: boolean;
  @Input() readonly addedInFooterTitle: string;
  @Input() readonly addedInFooterLoading: boolean;

  @Output()
  readonly cellClick: EventEmitter<IGridCellEventArgs> = new EventEmitter<IGridCellEventArgs>();

  @Output()
  readonly doubleClick: EventEmitter<IGridCellEventArgs> = new EventEmitter<IGridCellEventArgs>();

  @Output()
  readonly selected: EventEmitter<IGridCellEventArgs> = new EventEmitter<IGridCellEventArgs>();

  @Output() readonly cellEdit = new EventEmitter<IGridEditEventArgs>();
  @Output() readonly cellEditDone = new EventEmitter<IGridEditDoneEventArgs>();
  @Output() readonly cellEditEnter = new EventEmitter<IGridEditEventArgs>();
  @Output() readonly cellEditExit = new EventEmitter<IGridEditDoneEventArgs>();

  @Output() readonly rowEdit = new EventEmitter<IGridEditEventArgs>();
  @Output() readonly rowEditDone = new EventEmitter<IGridEditDoneEventArgs>();
  @Output() readonly rowEditEnter = new EventEmitter<IGridEditEventArgs>();
  @Output() readonly rowEditExit = new EventEmitter<IGridEditDoneEventArgs>();
  @Output() readonly pageChange = new EventEmitter<number>();
  @Output() readonly rendered: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  readonly sorting: EventEmitter<ISortingEventArgs> = new EventEmitter<ISortingEventArgs>();

  @Output()
  readonly rowSelectionChanging: EventEmitter<IRowSelectionEventArgs> = new EventEmitter<IRowSelectionEventArgs>();

  @Output()
  readonly columnMovingEnd: EventEmitter<IColumnMovingEndEventArgs> = new EventEmitter<IColumnMovingEndEventArgs>();

  @Output()
  readonly filtering: EventEmitter<IFilteringEventArgs> = new EventEmitter<IFilteringEventArgs>();

  @Output()
  readonly filteringDone: EventEmitter<IFilteringExpressionsTree> = new EventEmitter<IFilteringExpressionsTree>();

  @Output()
  readonly filteringExpressionsTreeChange: EventEmitter<IFilteringExpressionsTree> =
    new EventEmitter<IFilteringExpressionsTree>();

  @Output()
  readonly rowExpanded: EventEmitter<T> = new EventEmitter<T>();

  @Output()
  readonly rowDragEnd = new EventEmitter<IRowDragEndEventArgs>();

  @Output()
  readonly rowDeleted = new EventEmitter<IRowDataEventArgs>();

  @Output() readonly rowLazyAdded = new EventEmitter<RowType>();
  @Output() readonly rowLazyDeleted = new EventEmitter<RowType>();

  @Output() readonly rowAdding = new EventEmitter<void>();
  @Output() readonly rowAdded = new EventEmitter<IRowDataEventArgs>();

  @ContentChildren(GridColumnComponent)
  readonly columns: QueryList<GridColumnComponent>;

  #height: string | null = '100%';
  #width: string | null = '100%';
  #dataCloneStrategy: IDataCloneStrategy = new DefaultDataCloneStrategy();

  protected showContextMenu = false;
  protected contextMenuX = 0;
  protected contextMenuY = 0;
  protected targetCell: CellType;
  protected previousIconName: PaginationIcon = 'left-caret';
  protected nextIconName: PaginationIcon = 'right-caret';

  private readonly dir;
  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly elementRef: ElementRef<HTMLElement>,
    @Inject(LOCALE_ID) private readonly locale: string,
  ) {
    super();

    this.dir = getLocaleDirection(this.locale);
    this.previousIconName = this.dir === 'ltr' ? 'left-caret' : 'right-caret';
    this.nextIconName = this.dir === 'ltr' ? 'right-caret' : 'left-caret';
  }

  onToggleRow(e: MouseEvent, rowId: string): void {
    e.stopPropagation();

    const row = this.igxGrid.getRowByKey(rowId);

    const data = row.data as T;

    const expanded = row.expanded;

    if (!this.#expandAll) {
      this.igxGrid.collapseAll();
    }

    if (expanded) {
      this.igxGrid.collapseRow(rowId);
    } else {
      this.igxGrid.expandRow(rowId);
      this.rowExpanded.emit(data);
    }

    this.markForCheck();
    this.cdr.detectChanges();
  }

  getSelectedRows(): string[] {
    return this.igxGrid.selectedRows as string[];
  }

  getCellByColumn(rowIndex: number, columnField: string): CellType {
    return this.igxGrid.getCellByColumn(rowIndex, columnField);
  }

  clearRowSelection(): void {
    this.igxGrid.deselectAllRows();
  }

  endEdit(): void {
    this.igxGrid.endEdit();
  }

  deselectAllRows(onlyFilterData?: boolean): void {
    this.igxGrid.deselectAllRows(onlyFilterData);
  }

  filterColumnByString(term: string, key: string, ignoreCase = true): void {
    this.igxGrid.filter(key, term, IgxStringFilteringOperand.instance().condition('contains'), ignoreCase);
  }

  selectRows(rowIDs: unknown[], clearCurrentSelection?: boolean) {
    this.igxGrid.selectRows(rowIDs, clearCurrentSelection);
  }

  clearSort(name?: string): void {
    this.igxGrid.clearSort(name);
  }

  sort(expression: ISortingExpression | Array<ISortingExpression>): void {
    this.igxGrid.sort(expression);
  }

  getRowByKey(key: unknown): RowType {
    return this.igxGrid.getRowByKey(key);
  }

  beginAddRowByIndex(index: number): void {
    this.igxGrid.beginAddRowByIndex(index);
  }

  markForCheck(): void {
    this.igxGrid.markForCheck();
  }

  commitTransactions(data: T[]): void {
    this.igxGrid.transactions.commit(data);
  }

  startAddRow(rowContext: RowType): void {
    if (this.lazyAdd) {
      this.rowLazyAdded.emit(rowContext);
    } else {
      rowContext.beginAddRow();
    }
  }

  startEditRow(rowContext: RowType): void {
    const firstEditable = rowContext.cells.filter(cell => cell.editable)[0];
    const grid = rowContext.grid;

    if ((grid.rowList as RowType[]).filter(row => row === rowContext).length !== 0) {
      (grid.gridAPI.crudService as IgxGridCRUDService).enterEditMode(firstEditable);
      firstEditable.activate(null);
    }
  }

  deleteRow(rowContext: RowType): void {
    if (this.lazyDeletion) {
      this.rowLazyDeleted.emit(rowContext);
    } else {
      rowContext.delete();
    }
  }

  inEditMode(rowContext: RowType): boolean {
    return rowContext && (rowContext.inEditMode || rowContext.cells.some(cell => cell.editMode));
  }

  trackColumnChanges(index: number, col: GridColumnComponent): string {
    return col.field + col.header;
  }

  protected contextMenuHandler(eventArgs: IGridCellEventArgs): void {
    if (this.contextMenuTemplate?.template) {
      const event: MouseEvent = eventArgs.event as MouseEvent;

      event.preventDefault();
      this.contextMenuX = event.clientX;
      this.contextMenuY = event.clientY;
      this.targetCell = eventArgs.cell;
      this.showContextMenu = true;
    }
  }

  protected hideContextMenu(): void {
    this.showContextMenu = false;
    this.cdr.markForCheck();
  }

  onRowAdd(): void {
    this.rowAdding.emit();
  }

  onRowAdded($event: IRowDataEventArgs): void {
    this.rowAdded.emit($event);
  }

  onRowDeleted($event: IRowDataEventArgs): void {
    this.rowDeleted.emit($event);
  }

  onPageChange($event: number): void {
    if (this.page === $event) {
      return;
    }

    this.pageChange.emit($event);
  }

  onRowDragEnd($event: IRowDragEndEventArgs): void {
    this.rowDragEnd.emit($event);
  }

  onDropAllowed(args: IDropDroppedEventArgs): void {
    const event = args.originalEvent as { clientX: number; clientY: number };
    const currRowIndex = this.getCurrentRowIndex(this.igxGrid.rowList.toArray(), {
      x: event.clientX,
      y: event.clientY,
    });

    if (currRowIndex === -1) {
      return;
    }

    this.igxGrid.deleteRow((args.dragData as { key: string }).key);
    this.data.splice(currRowIndex, 0, (args.dragData as { data: T }).data);
  }

  private getCurrentRowIndex(rowList: IgxRowDirective[], cursorPosition: { x: number; y: number }) {
    for (const row of rowList) {
      const rowRect = row.nativeElement.getBoundingClientRect();

      if (
        cursorPosition.y > rowRect.top + window.scrollY &&
        cursorPosition.y < rowRect.bottom + window.scrollY &&
        cursorPosition.x > rowRect.left + window.scrollX &&
        cursorPosition.x < rowRect.right + window.scrollX
      ) {
        // TODO: Fails here
        return this.data.findIndex(r => r[this.primaryKey] === row.key);
      }
    }

    return -1;
  }
}
