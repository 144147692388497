import { useAnimation } from '@angular/animations';
import { HorizontalAlignment, PositionSettings, VerticalAlignment } from '@infragistics/igniteui-angular';
import {
  fadeIn,
  fadeOut,
  slideInBl,
  slideInBottom,
  slideInBr,
  slideInLeft,
  slideInRight,
  slideInTl,
  slideInTop,
  slideInTr,
  slideOutBl,
  slideOutBottom,
  slideOutBr,
  slideOutLeft,
  slideOutRight,
  slideOutTl,
  slideOutTop,
  slideOutTr,
} from '@infragistics/igniteui-angular/animations';

export type SnackbarPosition =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'bottom-left'
  | 'bottom'
  | 'bottom-right'
  | 'right'
  | 'left'
  | 'center';

export type SnackbarState = 'open' | 'close';

export const snackbarPositionSettings: Record<SnackbarPosition, PositionSettings> = {
  'top-left': {
    horizontalDirection: HorizontalAlignment.Left,
    horizontalStartPoint: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Top,
    openAnimation: useAnimation(slideInTl, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(slideOutTl, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
  top: {
    horizontalDirection: HorizontalAlignment.Center,
    horizontalStartPoint: HorizontalAlignment.Center,
    verticalStartPoint: VerticalAlignment.Top,
    openAnimation: useAnimation(slideInTop, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(slideOutTop, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
  'top-right': {
    horizontalDirection: HorizontalAlignment.Right,
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Top,
    openAnimation: useAnimation(slideInTr, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(slideOutTr, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
  'bottom-left': {
    horizontalDirection: HorizontalAlignment.Left,
    horizontalStartPoint: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom,
    openAnimation: useAnimation(slideInBl, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(slideOutBl, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
  bottom: {
    horizontalDirection: HorizontalAlignment.Center,
    horizontalStartPoint: HorizontalAlignment.Center,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom,
    openAnimation: useAnimation(slideInBottom, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(slideOutBottom, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
  'bottom-right': {
    horizontalDirection: HorizontalAlignment.Right,
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom,
    openAnimation: useAnimation(slideInBr, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(slideOutBr, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
  center: {
    horizontalDirection: HorizontalAlignment.Center,
    horizontalStartPoint: HorizontalAlignment.Center,
    verticalStartPoint: VerticalAlignment.Middle,
    verticalDirection: VerticalAlignment.Middle,
    openAnimation: useAnimation(fadeIn, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(fadeOut, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
  right: {
    horizontalDirection: HorizontalAlignment.Right,
    horizontalStartPoint: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Middle,
    verticalDirection: VerticalAlignment.Middle,
    openAnimation: useAnimation(slideInRight, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(slideOutRight, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
  left: {
    horizontalDirection: HorizontalAlignment.Left,
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Middle,
    verticalDirection: VerticalAlignment.Middle,
    openAnimation: useAnimation(slideInLeft, { params: { duration: '250ms' } }),
    closeAnimation: useAnimation(slideOutLeft, { params: { duration: '150ms' } }),
    minSize: { height: 100, width: 300 },
  },
} as const;
