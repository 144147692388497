import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';

import { SupyCommonModule } from '@supy/common';

import { AutocompleteModule } from '../autocomplete';
import { ButtonModule } from '../button';
import { CheckboxModule } from '../checkbox';
import { ComboboxModule } from '../combo-box';
import { CounterInputModule } from '../counter-input';
import { DropdownModule } from '../dropdown';
import { EditableOptionsModule } from '../editable-options';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { PaginationModule } from '../pagination';
import { SelectModule } from '../select';
import { StatusBadgeModule } from '../status-badge';
import { SwitchModule } from '../switch';
import { TimePickerModule } from '../time-picker';
import { TooltipModule } from '../tooltip';
import {
  AutocompleteCellEditorComponent,
  ButtonGroupCellEditorComponent,
  EditableOptionsCellEditorComponent,
  InputCellEditorComponent,
  InputCounterCellEditorComponent,
  SelectCellEditorComponent,
  SwitchCellEditorComponent,
  TimePickerCellEditorComponent,
} from './cell-editors';
import {
  ActionsCellRendererComponent,
  AutocompleteCellRendererComponent,
  ButtonGroupCellRendererComponent,
  CheckboxCellRendererComponent,
  EditableOptionsCellRendererComponent,
  IconStatusBadgeCellRendererComponent,
  InputCounterCellRendererComponent,
  SwitchCellRendererComponent,
  SwitchViewCellRendererComponent,
  TextWithIconCellRendererComponent,
  TimePickerCellRendererComponent,
} from './cell-renderers';
import { HeaderWithIconComponent } from './components';
import { GridPocComponent } from './grid-poc.component';
import { NoRowsOverlayComponent } from './no-row-overlay';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

@NgModule({
  declarations: [
    ActionsCellRendererComponent,
    AutocompleteCellEditorComponent,
    AutocompleteCellRendererComponent,
    ButtonGroupCellEditorComponent,
    ButtonGroupCellRendererComponent,
    CheckboxCellRendererComponent,
    EditableOptionsCellEditorComponent,
    EditableOptionsCellRendererComponent,
    GridPocComponent,
    HeaderWithIconComponent,
    IconStatusBadgeCellRendererComponent,
    InputCellEditorComponent,
    SwitchCellRendererComponent,
    SwitchCellEditorComponent,
    SelectCellEditorComponent,
    TextWithIconCellRendererComponent,
    SwitchViewCellRendererComponent,
    TimePickerCellRendererComponent,
    TimePickerCellEditorComponent,
    InputCounterCellRendererComponent,
    InputCounterCellEditorComponent,
    NoRowsOverlayComponent,
  ],
  imports: [
    AgGridModule,
    AutocompleteModule,
    ButtonModule,
    CheckboxModule,
    ComboboxModule,
    CommonModule,
    DropdownModule,
    EditableOptionsModule,
    IconModule,
    InputModule,
    PaginationModule,
    RouterLink,
    SelectModule,
    StatusBadgeModule,
    CheckboxModule,
    DropdownModule,
    SwitchModule,
    SupyCommonModule,
    TooltipModule,
    TimePickerModule,
    CounterInputModule,
  ],
  exports: [GridPocComponent],
  bootstrap: [GridPocComponent],
})
export class GridPocModule {}
