import { useAnimation } from '@angular/animations';
import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import {
  AutoPositionStrategy,
  ConnectedPositioningStrategy,
  IgxTooltipTargetDirective,
  PositionSettings,
} from '@infragistics/igniteui-angular';
import { scaleInTop, scaleOutBottom } from '@infragistics/igniteui-angular/animations';

import { TooltipComponent } from '../../components';
import { TooltipPosition, tooltipPositionSettings } from './position-settings';

const CURSOR_CLASS = 'cursor--pointer';

interface MouseLeaveEvent extends MouseEvent {
  readonly toElement: HTMLElement;
}

@Directive({
  selector: '[supyTooltip]',
  providers: [IgxTooltipTargetDirective],
})
export class TooltipDirective implements OnInit {
  @Input() readonly supyTooltip: TooltipComponent;
  @Input() readonly position: TooltipPosition = 'bottom';
  @Input() readonly allowHoverTooltip: boolean = true;
  @Input() readonly allowReposition: boolean;

  @Input() readonly elementRef: ElementRef;
  @Input() readonly hideDelay: number = 400;
  @HostBinding('class.' + CURSOR_CLASS) readonly cursorPointer = true;

  constructor(private readonly tooltipTarget: IgxTooltipTargetDirective) {}

  ngOnInit(): void {
    this.tooltipTarget.target = this.supyTooltip.tooltip;

    this.tooltipTarget.outlet = this.elementRef ?? this.supyTooltip.outlet;
    this.tooltipTarget.hideDelay = this.hideDelay;

    const positionSettings: PositionSettings = {
      ...tooltipPositionSettings[this.position],
      openAnimation: useAnimation(scaleInTop, { params: { duration: '100ms' } }),
      closeAnimation: useAnimation(scaleOutBottom, { params: { duration: '100ms' } }),
      minSize: { width: 200, height: 0 },
    };

    this.tooltipTarget.overlaySettings = {
      outlet: this.tooltipTarget.outlet,
      positionStrategy: this.allowReposition
        ? new AutoPositionStrategy(positionSettings)
        : new ConnectedPositioningStrategy(positionSettings),
    };

    this.tooltipTarget.ngOnInit();
  }

  @HostListener('click')
  onClick(): void {
    this.tooltipTarget.onClick();
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.allowHoverTooltip) {
      this.tooltipTarget.onMouseEnter();
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseLeaveEvent): void {
    const mouseTarget = event.toElement;

    if (
      this.allowHoverTooltip &&
      (mouseTarget?.classList.contains('supy-tooltip') ||
        mouseTarget?.firstElementChild?.classList.contains('supy-tooltip'))
    ) {
      return this.mouseOverTooltip(mouseTarget);
    }

    this.tooltipTarget.onMouseLeave();
  }

  @HostListener('touchstart')
  onTouchStart(): void {
    this.tooltipTarget.onTouchStart();
  }

  @HostListener('touchstart', ['$event'])
  onDocumentTouchStart(event: TouchEvent): void {
    this.tooltipTarget.onDocumentTouchStart(event);
  }

  private mouseOverTooltip(mouseTarget: Element) {
    const tooltipLeaveHandler = () => {
      this.tooltipTarget.onMouseLeave();
    };

    mouseTarget.addEventListener('mouseleave', tooltipLeaveHandler, { once: true });
  }
}
